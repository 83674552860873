<script setup>
	// Libraries
	import { storeToRefs } from 'pinia';

	// Stores
	import { useHoursStore } from '@/stores/hours';

	// Composables
	import { getNowSafely } from '~/composables/helpers/date';

	// Store data
	const { getStatus } = storeToRefs(useHoursStore());

	const now = await getNowSafely();
	const status = getStatus.value(now.value.getTime());
</script>

<template>
	<ClientOnly>
		<span class="status-message fs-12" v-if="status">
			<span :class="`${status?.open ? 'open' : 'closed'} status-circle`" />
			<span v-html="status?.message" />
		</span>
	</ClientOnly>
</template>

<style lang="scss">
	.status-message {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		background: white;
		padding: 0.75rem 1.25rem;
		border-radius: 20px;

		span {
			font-size: inherit;
			color: var(--black, #000);
			text-align: center;
			leading-trim: both;

			text-edge: cap;
			font-style: normal;
			font-weight: 900;
			line-height: 123.1%; /* 14.772px */
			letter-spacing: 1.38px;
			text-transform: uppercase;
		}

		.status-circle {
			width: 0.75em;
			height: 0.75em;
			border-radius: 50%;
			background: var(--gray-700);

			&.open {
				background: #46af15;
			}
		}
	}
	@media (max-width: $mobile) {
		html body .status-message {
			font-size: 10px;
		}
	}
</style>
